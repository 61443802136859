import { formatISODateTimeZone } from "@clipboard-health/date-time";
import { HorizontalScrollView } from "@clipboard-health/ui-components";
import { BaseLink, Text } from "@clipboard-health/ui-react";
// eslint-disable-next-line no-restricted-imports
import { Button, Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import {
  InterviewStatus,
  transformResponseIntoInterview,
  useFetchPaginatedInterviews,
} from "@src/appV2/Shifts/MyShifts/api/useFetchPaginatedInterviews";
import { type Worker } from "@src/appV2/Worker/api/types";
import { parseISO } from "date-fns";
import { useMemo } from "react";

import { InterviewCarouselCard } from "./InterviewCarouselCard";

export function InterviewsCarousel({ worker }: { worker: Worker }) {
  // only compute the start date on the component mount
  const start = useMemo(() => formatISODateTimeZone(new Date(), { timeZone: "UTC" }), []);
  const { data: interviews, isSuccess } = useFetchPaginatedInterviews({
    filter: {
      status: InterviewStatus.BOOKED,
      start: {
        gt: start,
      },
      workerId: worker.userId,
    },
  });
  const renderInterviews =
    interviews?.pages
      .flatMap((page) => page.data)
      .sort((a, b) => {
        return parseISO(a.attributes.start).getTime() - parseISO(b.attributes.start).getTime();
      })
      .map((interview) =>
        transformResponseIntoInterview(
          interview,
          interviews.pages.flatMap((page) => page.included ?? []),
          worker.tmz
        )
      )
      .slice(0, 6) ?? [];

  if (!isSuccess || renderInterviews.length === 0) {
    return null;
  }

  return (
    <Stack spacing={5}>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{ paddingX: 5 }}
      >
        <Stack direction="row" spacing={3} alignItems="baseline">
          <Text semibold variant="h4">
            Interviews
          </Text>
          <Text variant="body2">{interviews.pages.flatMap((page) => page.data).length}</Text>
        </Stack>
        <Button
          variant="text"
          size="small"
          sx={{
            color: (theme) => theme.palette.text.primary,
            textDecoration: "underline",
            textUnderlineOffset: 4,
          }}
          component={BaseLink}
          to={DeprecatedGlobalAppV1Paths.MY_SHIFTS}
        >
          View all
        </Button>
      </Stack>

      <HorizontalScrollView stretch sx={{ paddingX: 5 }}>
        {renderInterviews.map((interview) => (
          <InterviewCarouselCard key={interview.id} interview={interview} />
        ))}
      </HorizontalScrollView>
    </Stack>
  );
}
